import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'
import { useMount, useUnmount } from 'react-use';
import { Element, scroller } from 'react-scroll';
import { withHeader } from '../components/Header/HeaderContext';

import { Block, PostIndex } from '../components'

import { Layout } from '../components'
import { container, padding, bgImage, pagePadding, pageHeading, hoverState, blockPadding, bodyLink } from '../styles/global'
import { parseACF } from '../utils'
import { legacyHeading2, subheading } from '../styles/type'
import { yellow } from '../styles/colours'
import { media } from '../styles/utils'

const Contact = (props) => {
	const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true)

    const renderIntro = () => {
        const { contact_text, contact_sub_heading } = data;

        return (
            <Intro>
                <Subheading
                    as={'h1'}
                >
                    {contact_sub_heading}
                </Subheading>

                <Description
                    dangerouslySetInnerHTML={{__html: contact_text}}
                />
            </Intro>
        )
    }

    const renderImage = () => {
        const { contact_image_type, contact_image_caption } = data;

        return (
            <Block
                layout={'image_hotspot'}
                image={data.contact_image}
                {...data.contact_image_with_hotspots}
                use_caption={contact_image_caption}
                caption={contact_image_caption}
                display
                lastBlock
            />
        )
    }

    const renderOurStudio = () => {
        if (!data.studio_display_section) return;
        const { studio_sub_heading, studio_initial_text, studio_expanded_text } = data;
        const [textExpanded, setTextExpanded] = useState(false)

        return (
            <OurStudio>
                <Container>
                    <Subheading>
                        {studio_sub_heading}
                    </Subheading>

                    <Description
                        dangerouslySetInnerHTML={{__html: studio_initial_text}}
                    />

                    <ExpandedText
                        animate={textExpanded ? 'active' : 'hidden'}
                        variants={animatedText}
                        dangerouslySetInnerHTML={{__html: studio_expanded_text}}
                    />

                    <ReadMore
                        onClick={() => setTextExpanded(!textExpanded)}
                    >
                        {textExpanded ? 'Read Less' : 'Read More'}
                    </ReadMore>
                </Container>
            </OurStudio>
        )
    }

    const renderOpportunities = () => {
        if (!data.opportunities_display_section) return;
        const { opportunities_sub_heading, opportunities_text } = data;

        return (
            <Opportunities
                id={'opportunities'}
            >
                <Subheading>
                    {opportunities_sub_heading}
                </Subheading>

                <Description
                    dangerouslySetInnerHTML={{__html: opportunities_text}}
                />
            </Opportunities>
        )
    }

    const renderOpenings = () => {
        if (!data.openings_display_section) return;
        const { openings_list, opportunities_sub_heading } = data;

        const items = openings_list.map((item, i) => {
            const { short_text, title, date } = item;

            return (
                <Item
                    key={i}
                >
                    <Description
                        dangerouslySetInnerHTML={{__html: short_text}}
                    />

                    <JobTitle>
                        {`${title} ${date}`}
                    </JobTitle>
                </Item>
            )
        })

        return (
            <Openings>
                <Subheading>
                    Openings
                </Subheading>

                <Items>
                    {items}
                </Items>
            </Openings>
        )
    }

	return (
		<Layout
            meta={data.seo}
        >
			<Wrapper>
                <Container>
                    {renderIntro()}
                    {renderImage()}
                </Container>

                {renderOurStudio()}

                <Container>
                    {renderOpportunities()}
                    {renderOpenings()}
                </Container>
			</Wrapper>
		</Layout>
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``
const Item = styled.div``
const Items = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
    ${blockPadding}
	display: flex;
	flex-direction: column;
    align-items: flex-start;
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

    > ${Container}:first-child {
        ${pagePadding}
    }

    /* Hotspot image override */

	.hotspot-img {
        max-height: 640px;
        max-width: 100vw;
    }

    /* Hide hotspot caption */

    ${media.phone`        
        .caption {
            display: none;
        }
    `}
`

// Contact

const Intro = styled.div`
    ${Subheading} {
        ${pageHeading}

        ${media.phone`
            margin-bottom: 47px;
        `}
    }

    ${Description} {
        margin-bottom: 90px;

        ${media.phone`
            margin-bottom: 134px;
        `}

        &, p {
            ${legacyHeading2}

            a {
                ${bodyLink}
            }
        }
    }
`

// Our Studio

const animatedText = {
	hidden: {
		opacity: 0,
		height: 0,
		pointerEvents: 'none',
		transition: {
			ease: 'easeOut'
		}
	},
	active: {
		opacity: 1,
		height: 'auto',
		pointerEvents: 'auto',
		transition: {
			ease: 'easeOut'
		}
	}
}

const ExpandedText = styled(motion.div)``
const ReadMore = styled.div``

const OurStudio = styled.div`
    background: ${yellow};
    width: 100vw;
    display: flex;
	flex-direction: column;
	align-items: center;
    padding: 96px 0;
    margin-top: 172px;

    ${Subheading},
    ${ReadMore} {
        ${subheading}
    }

    ${Subheading} {
        ${pageHeading}
    }

    ${ReadMore} {
        margin-top: 32px;
        user-select: none;
        ${hoverState}

        ${media.phone`
            margin-top: 23px;
        `}
    }

    ${Description},
    ${ExpandedText} {
        &, p {
            ${legacyHeading2}

            a {
                ${hoverState}
            }
        }
    }

    ${media.phone`
        margin-top: 0;
        padding: 33px 0;
    `}
`

// Opportunities

const Opportunities = styled.div`
    padding-top: 96px;

    ${Subheading} {
        ${pageHeading}
    }

    ${Description} {
        margin-bottom: 70px;

        &, p {
            ${legacyHeading2}

            a {
                ${hoverState}
            }
        }
    }

    ${media.phone`
        padding-top: 32px;
    `}
`

// Openings

const JobTitle = styled.div``

const Openings = styled.div`
    margin-top: 55px;

    > ${Subheading} {
        ${pageHeading}
    }

    ${Items} {
        margin-top: 76px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        ${Item} {
            flex: 0 1 calc(50% - 32px);
            flex-basis: calc(50% - 32px);

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            ${Description} {
                margin-bottom: 32px;

                &, p {
                    ${legacyHeading2}

                    a {
                        ${hoverState}
                    }
                }
            }

            ${JobTitle} {
                height: 23px;
                background: ${yellow};
                height: 23px;
                border-radius: 6px;
                padding: 10px;

                display: flex;
                align-items: center;
                justify-content: center;
                ${hoverState};

                &:not(:last-child) {
                    margin-right: 16px;
                }
            }

            ${media.phone`
                flex: 0 1 100%;

                ${Description} {
                    margin-bottom: 24px;
                }

                ${JobTitle} {
                    font-size: 12px;
                    line-height: 1.25;
                }

                &:not(:last-child) {
                    margin-bottom: 70px;
                }
            `}
        }
    }

    ${media.phone`
        margin-top: 0;

        ${Items} {
            margin-top: 30px;
        }
    `}
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "contact" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default withHeader(Contact)